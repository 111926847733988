'use strict';

angular.module('dcms.login').directive('bannerMap',
/** @ngInject */
function ($compile) {

    return {
        restrict: 'A',
        replace: true,
        template: '',
        scope: {
            settings: '=bannerMap'
        },
        link: function ($scope, $elt, attrs) {
            $scope.location = "";
            
            $scope.$watch("settings", function(val){
                if(val === undefined) {
                    $scope.location = "Berlin";
                }
                else
                    $scope.location = val.location;
            }, true);
        }
    };
});