'use strict';

/**
 * TMS Base style must be pure JS script, as login screen also needs it, but it
 * lacks angular dependency.
 */
var tmsBaseStyle = (function(){
    function TmsBaseStyle(){
        this.font = {
            fallback: 'Arial'
        };
    }
    
    TmsBaseStyle.prototype.applyFontFamily = function(fontFamily){
        var fontValue = '';
        if(fontFamily)
            fontValue = fontFamily + ', ' + this.font.fallback + ' !important';
        else
            fontValue = this.font.fallback + ' !important';
        
        this.overrideStyle('div, span, p, h1, h2, h3, h4, h5, h6, h7, label, input, textarea, button, select, body { font-family: ' + fontValue + '; }');
    };
    
    TmsBaseStyle.prototype.overrideStyle = function(style){
        if($('style#tms-base-style-override').length > 0){
            $('style#tms-base-style-override').remove();
        }
        $('<style id="tms-base-style-override" type="text/css">' + style + '</style>').appendTo('head');
    };
    
    return new TmsBaseStyle();
})();