'use strict';

(function () {
    angular.module('litui').factory('LituiLoginFactory', LituiLoginFactoryWrapper);

    /** @ngInject */
    function LituiLoginFactoryWrapper($rootScope, $compile, $timeout, LituiLoginService) {
        function LituiLoginFactory($elt, ctrl, settings) {
            this.$elt = $elt;
            this.ctrl = ctrl;

            this.settings = litui.lang.deepMerge(LituiLoginFactory.defaultSettings, settings || {});

            this.availableScreens = [
                {
                    id: 'litui-login-form',
                    showBackBtn: false,
                    settings: this.settings.loginForm
                },
                {
                    id: 'litui-change-password-form',
                    showBackBtn: true,
                    backIndex: 0,
                    settings: this.settings.changePasswordForm
                }
            ];
            
            this.scopes = [];
            this.loginService = new LituiLoginService(this);
        }

        LituiLoginFactory.defaultSettings = {
            onInit: function () {},
            authenticationService: 'authentication/login',
            changePasswordService: 'authentication/change-password',
            welcomeScreenIndex: 0,
            loginForm: {
                title: 'LITUI.LOGIN_FORM.TITLE',
                description: '',
                onInit: function () {}
            },
            changePasswordForm: {
                title: 'LITUI.CHANGE_PASSWORD_FORM.TITLE',
                description: 'LITUI.CHANGE_PASSWORD_DESC_FORM.DESC',
                requireOldPassword: false,
                onInit: function () {}
            },
            onSuccess: function () {
                litui.logger.warn("Login screen must implement its own .onSuccess(..) method");
            }
        };

        LituiLoginFactory.prototype.init = function () {
            var self = this;
            $('body').css('opacity', 0);

            this.prepareCtrl();

            return self.setActiveScreen(this.settings.welcomeScreenIndex);
        };

        LituiLoginFactory.prototype.destroy = function () {
            for(var k in this.scopes){
                this.scopes[k].$destroy();
            }
        };

        LituiLoginFactory.prototype.prepareCtrl = function () {
            this.ctrl.messages = {};
            this.ctrl.activeScreen = this.availableScreens[this.settings.welcomeScreenIndex];
        };

        LituiLoginFactory.prototype.renderActiveScreen = function () {
            var self = this;
            return litui.async.when(this.ctrl.activeScreen.settings.onInit).then(function (payload) {
                self.ctrl.messages = {};
                var $holder = self.$elt.find('.active-login-screen-holder .active-login-screen-body .login-screen-content');
                $holder.html('<' + self.ctrl.activeScreen.id + ' settings="settings" service="service"></' + self.ctrl.activeScreen.id + '>');
                self.$elt.find('.active-login-screen-holder').addClass(self.ctrl.activeScreen.id + '-holder');
                var scope = $rootScope.$new();
                scope.settings = self.ctrl.activeScreen.settings;
                scope.service = self.loginService;
                self.scopes.push(scope);
                $compile($holder)(scope);
                $timeout(function () {
                    $('body').css('opacity', 1);
                }, 0.5);
                return payload;
            });
        };

        LituiLoginFactory.prototype.setActiveScreen = function (index) {
            this.ctrl.activeScreen = this.availableScreens[index];
            this.renderActiveScreen();
        };

        LituiLoginFactory.prototype.onSuccess = function () {
            return this.settings.onSuccess();
        };

        return LituiLoginFactory;
    }
})();
"use strict";

(function () {

    angular.module('litui').service('LituiLoginService',
    /** @ngInject */
    function () {

        function LoginService(factory) {
            new litui.Subscribe(this, ['AUTH_COMPLETE', 'SET_MESSAGE']);
            
            this.activeCredentials = {
                username: '',
                password: ''
            };
            this.activeUsername = "";
            this.factory = factory;
        }
        
        LoginService.prototype.login = function(credentials){
            var self = this;
            return litui.request.POST(this.factory.settings.authenticationService, credentials).then(function(payload){    
                if(payload.type === "CHANGE_PASSWORD"){
                    self.activeCredentials = credentials;
                }
                
                self.trigger('AUTH_COMPLETE', payload);
                return payload;
            });
        };
        
        LoginService.prototype.changePassword = function(credentials){
            var self = this;
            credentials.username = this.activeCredentials.username;
            credentials.password = this.activeCredentials.password;
            return litui.request.POST(this.factory.settings.changePasswordService, credentials).then(function(payload){    
                self.trigger('AUTH_COMPLETE', payload);
                return payload;
            });
        };

        return LoginService;
    });
})();
'use strict';

(function () {
    angular.module('litui').component('lituiChangePasswordForm', {
        controller: LituiChangePasswordCtrl,
        templateUrl: 'litui.extra/litui-login/litui-change-password-form/litui-change-password-form.html',
        bindings: {
//            factory: '=',
            settings: '=',
            service: '='
        }
    });

    /** @ngInject */
    function LituiChangePasswordCtrl() {
        var self = this;

        this.$onInit = function(){
            var isOldPassword = litui.utils.isUndefined(this.settings.requireOldPassword)? true : this.settings.requireOldPassword; //this.factory.ctrl.activeScreen.settings.requireOldPassword;
            this.changePasswordForm = {
                def: {
                    formId: this.settings.formId,
                    description: this.settings.description, //this.factory.ctrl.activeScreen.settings.description,
                    fields: {
                        oldPassword: {
                            type: 'password',
                            label: 'LITUI.CHANGE_PASSWORD_FORM.PASSWORD_OLD',
                            isValidatePattern: false,
                            isVisible: isOldPassword,
                            isRequired: isOldPassword,
                            messages: {
                                'OLD_PASSWORD_MISSMATCH': "LITUI.CHANGE_PASSWORD_FORM.OLD_PASSWORD_MISSMATCH"
                            },
                            validate: function () {
                                if (litui.utils.isDefined(self.settings.oldPassword) && 
                                        self.changePasswordForm.def.fields.oldPassword.value !== self.settings.oldPassword) {
                                    return 'OLD_PASSWORD_MISSMATCH';
                                }
                                return true;
                            }
                        },
                        newPassword: {
                            type: 'password',
                            label: 'LITUI.CHANGE_PASSWORD_FORM.PASSWORD_NEW',
                            isRequired: true,
                            features: ['show-password'],
                            messages: {
                                'EQ_OLD_PASSWORD': "LITUI.CHANGE_PASSWORD_FORM.EQ_OLD_PASSWORD",
                                'CONTAINED_OLD_PASSWORD': 'LITUI.CHANGE_PASSWORD_FORM.CONTAINED_OLD_PASSWORD'
                            },
                            validate: function () {
                                if (!isOldPassword)
                                    return true;
                                if (self.changePasswordForm.data.oldPassword === self.changePasswordForm.data.newPassword) {
                                    return 'EQ_OLD_PASSWORD';
                                }
                                if (self.changePasswordForm.data.newPassword.indexOf(self.changePasswordForm.data.oldPassword) > -1) {
                                    return 'CONTAINED_OLD_PASSWORD';
                                }
                                return true;
                            }
                        },
                        repeatPassword: {
                            type: 'password',
                            label: 'LITUI.CHANGE_PASSWORD_FORM.PASSWORD_REPEAT',
                            isRequired: true,
                            features: ['show-password'],
                            dependsOn: [
                                'newPassword'
                            ],
                            messages: {
                                'PASSWORDS_MUST_MATCH': "LITUI.CHANGE_PASSWORD_FORM.PASSWORDS_MUST_MATCH"
                            },
                            validate: function (value) {
                                if (self.changePasswordForm.data.newPassword !== value) {
                                    return 'PASSWORDS_MUST_MATCH';
                                }
                                return true;
                            }
                        }
                    },
                    showSubmit: litui.utils.isDefined(this.settings.showSubmit)? this.settings.showSubmit : true,
                    submitBtn: {
                        styleClass: 'litui-btn-post',
                        label: 'LITUI.CHANGE_PASSWORD_FORM.SUBMIT',
                        icon: 'icon-log_in'
                    },
                    onSubmit: litui.utils.isFunction(this.settings.onSubmit)? this.settings.onSubmit : function () {
    //                    self.factory.loginService.changePassword({
                        self.service.changePassword({
                            password: self.changePasswordForm.data.oldPassword,
                            newPassword: self.changePasswordForm.data.newPassword
                        });
                    }
                },
                data: {
                    oldPassword: "",
                    newPassword: "",
                    repeatPassword: ""
                }
            };
        };
    }
})();
'use strict';

(function(){
    angular.module('litui').component('lituiLoginForm', {
        controller: LituiFormCtrl,
        templateUrl: 'litui.extra/litui-login/litui-login-form/litui-login-form.html',
        bindings: {
//            factory: '=',
            settings: '=',
            service: '='
        }
    });
    
    /** @ngInject */
    function LituiFormCtrl() {
        var self = this;
        
        this.loginForm = null;
        
        this.$onInit = function(){
            this.loginForm = {
                def: {
                    description: this.settings.description, //this.factory.ctrl.activeScreen.settings.description,
                    fields: {
                        username: {
                            type: 'string',
                            label: 'LITUI.LOGIN_FORM.USERNAME',
                            value: '',
                            isRequired: true
                        },
                        password: {
                            type: 'password',
                            label: 'LITUI.LOGIN_FORM.PASSWORD',
                            value: '',
                            isValidatePattern: false,
                            isRequired: true
                        }
                    },
                    showSubmit: true,
                    submitBtn: {
                        styleClass: 'litui-btn-post',
                        label: 'LITUI.LOGIN_FORM.SUBMIT',
                        icon: 'icon-log_in'
                    },
                    onSubmit: function(data){
                        return self.service.login(data);
                    }
                },
                data: {
                    username: "",
                    password: ""
                }
            };
        }
    }
})();
'use strict';

(function () {
    angular.module('litui').component('lituiLoginScreen', {
        controller: DcmsLoginScreenCtrl,
        templateUrl: 'litui.extra/litui-login/litui-login-screen/litui-login-screen.html',
        transclude: {
            "lituiLoginFormContent": "?lituiLoginFormContent",
            "lituiLoginChangePasswordContent": "?lituiLoginChangePasswordContent"
        },
        bindings: {
            settings: '=',
            additionalMessages: '='
        }
    });

    /** @ngInject */
    function DcmsLoginScreenCtrl($element, $timeout, LituiLoginFactory) {
        var self = this;
        var $elt = $($element);
        this.messages = {};
        var loginFactory = null;

        this.$onInit = function () {            
            loginFactory = new LituiLoginFactory($elt, this, this.settings);
            
            loginFactory.loginService.on('AUTH_COMPLETE', function(payload){
                switch(payload.type){
                    case 'INVALID_IDENTITY':
                        loginFactory.loginService.trigger('SET_MESSAGE', {
                            type: 'error',
                            txt: 'LITUI.LOGIN_SCREEN.MSG_INVALID_IDENTITY'
                        });
                        shakeFx();
                        break;
                    case 'LOCKED':
                        loginFactory.loginService.trigger('SET_MESSAGE', {
                            type: 'error',
                            txt: 'LITUI.LOGIN_SCREEN.MSG_USER_ACCOUNT_LOCKED'
                        });
                        break;
                    case 'SUCCESS':
                        loginFactory.onSuccess();
                        break;
                    case 'CHANGE_PASSWORD':
                        $timeout(function(){
                            loginFactory.setActiveScreen(1);
                        });
                        break;
                    case 'OLD_PASSWORD_INVALID':
                        loginFactory.loginService.trigger('SET_MESSAGE', {
                            type: 'error',
                            txt: 'LITUI.LOGIN_SCREEN.MSG_OLD_PASSWORD_INVALID'
                        });
                        break;
                }
            });

            loginFactory.loginService.on('SET_MESSAGE', function(msg){
                self.messages = {}; // for now clear all messages, always
                self.messages[msg.txt] = msg;
            });
            return loginFactory.init();
        };

        this.$onDestroy = function () {            
            return loginFactory.destroy();
        };
        
        this.onBack = function(){
            loginFactory.setActiveScreen(this.activeScreen.backIndex);
        };
        
        function shakeFx(){
            var $e = $elt.find('.active-login-screen-holder');
            tweenShake($e).then(function(){
                return tweenShake($e);
            }).then(function(){
                return tweenShake($e);
            });
        }
        
        function tweenShake($e){
            return tweenPosition($e,-5).then(function(){
                return tweenPosition($e,10);
            }).then(function(){
                return tweenPosition($e,-5);
            });
        }
        
        function tweenPosition($e,x){
            var d = litui.async.defer();
            TweenMax.to($e, 0.05, {
                marginLeft: x,
                onComplete: function(){
                    d.resolve();
                }
            });
            return d.promise;
        }

    }
})();
angular.module("litui").run(function($templateCache){$templateCache.put("litui.extra/litui-login/litui-change-password-form/litui-change-password-form.html","<div class=\"change-password-form-holder litui-clearfix\">    <litui-form form-definition=$ctrl.changePasswordForm.def model=$ctrl.changePasswordForm.data></litui-form></div>");$templateCache.put("litui.extra/litui-login/litui-login-form/litui-login-form.html","<div class=litui-clearfix>    <litui-form form-definition=$ctrl.loginForm.def model=$ctrl.loginForm.data></litui-form></div>");$templateCache.put("litui.extra/litui-login/litui-login-screen/litui-login-screen.html","<div class=active-login-screen-holder>    <div class=active-login-screen-header>        <span class=logo ng-if=!$ctrl.activeScreen.showBackBtn></span>        <button ng-if=$ctrl.activeScreen.showBackBtn ng-click=$ctrl.onBack()>            <span class=\"litui-icon icon-previous\"></span>        </button>        <span litui-translate=$ctrl.activeScreen.settings.title></span>    </div>    <div class=active-login-screen-body>        <div class=login-screen-message>            <div ng-repeat=\"message in $ctrl.messages\" class=litui-msg-{{message.type}}>                <span litui-translate=message.txt></span>            </div>            <div ng-repeat=\"message in $ctrl.additionalMessages\" class=litui-msg-{{message.type}}>                <span litui-translate=message.txt></span>            </div>        </div>        <div class=login-screen-content></div>                <div ng-if=\"$ctrl.activeScreen.id === 'litui-login-form'\" ng-transclude=lituiLoginFormContent></div>        <div ng-if=\"$ctrl.activeScreen.id === 'litui-change-password-form'\" ng-transclude=lituiLoginChangePasswordContent></div>    </div></div>");});