"use strict";

(function () {

    angular.module('dcms.login').component('dcmsLogin', {
        controller: LoginCtrl,
        templateUrl: 'app/dcms-login.html'
    });

    /** @ngInject */
    function LoginCtrl($element, $rootScope, $scope, dcmsPublicDomainService, config) {
        var self = this;

        dcmsPublicDomainService.getMasterVersion();
        dcmsPublicDomainService.getProjectVersion();

        this.settings = {
            authenticationService: 'authentication/login',
            changePasswordService: 'authentication/change-password',
            onInit: function () {

            },
            onSuccess: function () {
                location.reload();
            }
        };
        this.isGmapApiLoaded = false;

        this.$onInit = function () {
            loadGoogleMapsApi();

            return dcmsPublicDomainService.pullDashboard().then(function (dashboardItems) {
                var $banner = $($element).find('.login-banner');

                var IMG_PATH = "../data/medialib/" + dashboardItems.banner;
                var img = new Image();
                img.src = IMG_PATH;
                img.onload = function () {
                    // read banner from medialib
                    $banner.css({
                        'background-image': 'url(' + IMG_PATH + ')'
                    });
                };
                self.location = dashboardItems.location;
            });
        };

        function loadGoogleMapsApi() {
            var d = litui.async.defer();
            var script = document.createElement('script');
            script.src = config.gmapUrl;
            script.onload = function () {
                self.isGmapApiLoaded = true;
                $scope.$apply();

                d.resolve();

            };
            script.onerror = function () {
                console.error("Could not load Google maps API");
                d.resolve();
            };
            document.body.appendChild(script);
            return d.promise;
        }

        function getLoginError() {
            return litui.request.GET('authentication/login-error').then(function (payload) {
                if (payload && payload.type && payload.type === 'EXCEPTION') {
                    self.azureActiveDirectoryMessages.loginError = {
                        type: 'error',
                        txt: 'An error has occurred. Please contact system administrator.'
                    };
                }
                return payload;
            });
        }
        getLoginError();
        self.azureActiveDirectorySettings = {
            enableAadLogin: config.azureActiveDirectory.loginEnabled,
            servletPath: config.azureActiveDirectory.appRedirectUriSignin
        };
        self.azureActiveDirectoryMessages = {
//            loginError: {
//                type: 'error',
//                txt: 'Something unexpected happened #woops'
//            }
        };

    }
})();